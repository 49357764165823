export default [
  {
    title: 'Tools',
    icon: 'EditIcon',
    action: 'employee',
    resource: 'manage',
    children: [
      {
        title: 'Impostazioni',
        route: 'tools-impostazioni-index',
        icon: 'SettingsIcon',
        action: 'employee',
        resource: 'manage',
      },
      {
        title: 'Email Template',
        route: 'tools-emailtemplate-index',
        icon: 'MailIcon',
        action: 'employee',
        resource: 'manage',
      },
      {
        title: 'Contratti Template',
        route: 'tools-contracttemplate-index',
        icon: 'FileTextIcon',
        action: 'employee',
        resource: 'manage',
      },
      {
        title: 'Log',
        route: 'tools-log-index',
        icon: 'AlertTriangleIcon',
        action: 'employee',
        resource: 'manage',
      },
      {
        title: 'Auto-Logout',
        route: 'crud-autologout',
        icon: 'LogOutIcon',
        action: 'employee',
        resource: 'manage',
      },
      
    ],
  },
]
