export default [
  {
    title: 'CRM',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Utenti',
        route: 'crm-utenti-index',
        icon: 'UserIcon',
        action: 'employee',
        resource: 'manage',
      },
      {
        title: 'Leads',
        route: 'crm-leads-index',
        icon: 'UsersIcon',
        action: 'employee',
        resource: 'read',
      },
      {
        title: 'Clienti',
        route: 'crm-customers-index',
        icon: 'BriefcaseIcon',
        action: 'employee',
        resource: 'read',
      },
      {
        title: 'Licenze',
        route: 'crm-licenze-index',
        icon: 'AwardIcon',
        action: 'employee',
        resource: 'read',
      },
      
    ],
  },
  {
    title: 'Amministrazione',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Pagamenti',
        route: 'crm-pagamenti-index',
        icon: 'CreditCardIcon',
        action: 'employee',
        resource: 'read',
      },
      {
        title: 'Fatture',
        route: 'crm-fatture-index',
        icon: 'FileTextIcon',
        action: 'employee',
        resource: 'read',
      },
      {
        title: 'Bonifici',
        route: 'crm-bonifici-index',
        icon: 'ClipboardIcon',
        action: 'employee',
        resource: 'read',
      },
    
    ]
  },
  {
    title: 'Campagne',
    icon: 'ApertureIcon',
    children: [
      {
        title: 'Agenti',
        route: 'crm-agenti-index',
        icon: 'DatabaseIcon',
        action: 'employee',
        resource: 'read',
      },
      {
        title: 'Ricerche',
        route: 'crm-ricerche-index',
        icon: 'SearchIcon',
        action: 'employee',
        resource: 'read',
      },
    ]
  },
]
